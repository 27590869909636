.header {
  padding: 10px;
  text-align: center;
  background: #1abc9c;
  color: white;
  font-size: 20px;
}

.party_image_right {
  float: right;
  height: 120px;
  width: 250px;
}
.party_image_left {
  float: left;
  height: 120px;
  width: 250px;
}
